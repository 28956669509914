import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as registrationOpgP1y95a5Meta } from "/app/pages/auth/registration.vue?macro=true";
import { default as contractorscgluBP6UA2Meta } from "/app/pages/contractors.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91line_93wDE3uGOqZMMeta } from "/app/pages/products/[line].vue?macro=true";
import { default as index18IXo8oN8QMeta } from "/app/pages/products/index.vue?macro=true";
import { default as products08Bd1RJ93nMeta } from "/app/pages/products.vue?macro=true";
import { default as indexaeouN7Kzh7Meta } from "/app/pages/profile/index.vue?macro=true";
import { default as subscriptionsoIX9jYPDmAMeta } from "/app/pages/profile/subscriptions.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as tXU8oQDH0gcMeta } from "/app/pages/t.vue?macro=true";
import { default as ui_45kitxRnkS5jbEfMeta } from "/app/pages/ui-kit.vue?macro=true";
export default [
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue")
  },
  {
    name: "auth-registration",
    path: "/auth/registration",
    meta: registrationOpgP1y95a5Meta || {},
    component: () => import("/app/pages/auth/registration.vue")
  },
  {
    name: "contractors",
    path: "/contractors",
    component: () => import("/app/pages/contractors.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: products08Bd1RJ93nMeta?.name,
    path: "/products",
    component: () => import("/app/pages/products.vue"),
    children: [
  {
    name: "products-line",
    path: ":line()",
    meta: _91line_93wDE3uGOqZMMeta || {},
    component: () => import("/app/pages/products/[line].vue")
  },
  {
    name: "products",
    path: "",
    meta: index18IXo8oN8QMeta || {},
    component: () => import("/app/pages/products/index.vue")
  }
]
  },
  {
    name: profileupBlgdy3vLMeta?.name,
    path: "/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue"),
    children: [
  {
    name: "profile",
    path: "",
    component: () => import("/app/pages/profile/index.vue")
  },
  {
    name: "profile-subscriptions",
    path: "subscriptions",
    component: () => import("/app/pages/profile/subscriptions.vue")
  }
]
  },
  {
    name: "t",
    path: "/t",
    component: () => import("/app/pages/t.vue")
  },
  {
    name: "ui-kit",
    path: "/ui-kit",
    component: () => import("/app/pages/ui-kit.vue")
  }
]